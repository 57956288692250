<template>
  <div class="px-10">
    <div class="text-h4 primary--text mb-5">
      {{ isUpdate ? 'แก้ไข' : 'สร้าง' }}ตั้งค่าธุรกิจ / สาขา
    </div>
    <v-row class="pl-15 mb-5">
      <v-col cols="3" class="text-center">
        <!-- <uploadImage
          directory="logo"
          :limit="2"
          :src="form.logo_url"
          @onChangeUrl="form.logo_url = $event"
        > -->
        <!-- <v-avatar color="primary" size="210" class="pointer"> -->
        <img :src="form.logo_url || $defaultImage" width="210" height="auto" />
        <!--  </v-avatar> -->
        <!-- </uploadImage> -->

        <div class="mt-5">
          หากต้องการเปลี่ยนรูปโลโก้ รบกวนแอดไลน์ id: @P2277 หรือ
          <a href="https://lin.ee/96rBlVP" target="_blank"
            >https://lin.ee/96rBlVP</a
          >
          <!-- <span
            class="layout-upload pointer primary--text"
            @click="deleteS3Obj(form.logo_url)"
            disabled
          >
            ลบรูป
          </span> -->
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols class="pl-15 mb-5">
        <!-- <v-text-field
          name="branch_name"
          label="ชื่อสาขา"
          outlined
          dense
          v-model="form.branch_name"
        /> -->
        <v-text-field
          name="store_name"
          label="ชื่อร้าน"
          outlined
          dense
          v-model="form.store_name"
        />
        <v-text-field
          name=""
          label="ชื่อออกใบเสร็จ"
          outlined
          dense
          v-model="form.name_for_receipt"
          :error-messages="$v.form.name_for_receipt.$error ? $error_text : ''"
        />
        <v-text-field
          name="phone_number"
          label="เบอร์ติดต่อ"
          outlined
          dense
          v-model="form.phone_number"
        />

        <v-text-field
          name=""
          label="ที่อยู่ (ไม่จำเป็น)"
          outlined
          dense
          @click="
            (dialogAddressData = form.location),
              (dialogAddress = true),
              (dialogAddressKey = 'location')
          "
          :value="`${form.location.address} ${form.location.sub_district} ${form.location.district} ${form.location.province} ${form.location.postal_code}`"
        />
        <v-text-field
          name=""
          label="เวลาทำการ (ควรใส่)"
          value="คลิกเพื่อเปิดดู"
          outlined
          dense
          @click="
            (dialogBusinessHoursData = form), (dialogBusinessHoursUpdate = true)
          "
        />
        <v-text-field
          name="line_id"
          label="Line ID (ควรใส่)"
          outlined
          dense
          v-model="form.line_id"
        />
        <v-text-field
          name="facebook"
          label="Facebook (ควรใส่)"
          outlined
          dense
          v-model="form.facebook"
        />

        <span>บัญชีธนาคารของร้าน {{ form.bank_account.length }} บัญชี</span>
        <v-btn
          class="ml-5"
          color="primary"
          small
          outlined
          @click="(dialogData = form.location), (dialogUpdate = true)"
        >
          แก้ไข
        </v-btn>
        <div class="mt-5">QR Code สำหรับรับเงิน</div>
        <v-col class="py-3">
          
          <!-- <uploadImage
          directory="qr_code_pay"
          :limit="2"
          :src="form.qr_code_payment"
          @onChangeUrl="form.qr_code_payment = $event"
        > -->
          <!-- <v-avatar color="primary" size="210" class="pointer"> -->
          <img
            :src="form.qr_code_payment || $defaultImage"
            width="150"
            :style="{ height: form.qr_code_payment ? 'auto' : '150px' }"
          />
          <!--  </v-avatar> -->
          <!-- </uploadImage> -->

          <div class="mb-5">
            หากต้องการเปลี่ยนรูป
            รบกวนแอดไลน์ id: @P2277 หรือ
            <a href="https://lin.ee/96rBlVP" target="_blank"
              >https://lin.ee/96rBlVP</a
            >
            <!-- <span
            class="layout-upload pointer primary--text"
            @click="deleteS3Obj(form.logo_url)"
            disabled
          >
            ลบรูป
          </span> -->
          </div>
          <v-select
            v-model="form.setting.show_qr_code_payment"
            label="แสดง QR Code ที่"
            outlined
            :items="list.scrap"
            item-value="value"
            item-text="name"
            dense
          ></v-select>
        </v-col>
      </v-col>

      <v-col cols offset="1">
        <div class="mb-5">ตำแหน่งที่ตั้ง (ไม่จำเป็น)</div>
        <v-col>
          <v-row>
            <v-text-field
              name="lat"
              label="ละติจูด"
              outlined
              dense
              v-model="form.geopoint.lat"
            />
            &nbsp; &nbsp;
            <v-text-field
              name="lng"
              label="ลองจิจูด"
              outlined
              dense
              v-model="form.geopoint.lng"
            />
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <div class="mt-1">เป็นนิติบุคคล ?</div>

            <v-radio-group class="mt-0 ml-10" row v-model="legalEntity1">
              <v-radio name="" label="เป็น" :value="true"></v-radio>
              <!-- <v-spacer /> -->
              <v-radio name="" label="ไม่เป็น" :value="false"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <div v-if="legalEntity1">
          <v-text-field
            name="company_branch"
            label="สาขาบริษัท"
            outlined
            dense
            v-model="form.company_branch"
          />
          <v-text-field
            name="taxpayer_number"
            label="เลขประจำตัวผู้เสียภาษี"
            outlined
            dense
            v-model="form.taxpayer_number"
            :error-messages="$v.form.taxpayer_number.$error ? $error_text : ''"
          />
          <v-text-field
            name="email"
            label="E - mail"
            outlined
            dense
            v-model="form.email"
            :error-messages="$v.form.email.$error ? $error_text : ''"
          />
        </div>

        <v-col>
          <v-row>
            <div class="mt-1">
              ภาษีมูลค่าเพิ่ม (กรุณาตั้งค่าให้เรียบร้อย ก่อนใช้งาน)
            </div>
            <v-radio-group
              class="mt-0 ml-10"
              row
              v-model="form.vat_registration"
            >
              <v-radio name="" label="จดภาษี" :value="true"></v-radio>
              <!-- <v-spacer /> -->
              <v-radio name="" label="ไม่จดภาษี" :value="false"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <div class="mt-1">แยกสินค้าบางอัน ไปบิลอีกใบนึง</div>
            <v-radio-group class="mt-0 ml-10" row v-model="form.is_split_bill">
              <v-radio name="" label="เปิดใช้งาน" :value="true" />
              <!-- <v-spacer /> -->
              <v-radio name="" label="ปิดใช้งาน" :value="false" />
            </v-radio-group>
          </v-row>
        </v-col>
      </v-col>
    </v-row>

    <v-divider class="mb-10" v-if="form.is_split_bill" />

    <div class="text-h4 primary--text mb-5" v-if="form.is_split_bill">
      {{ isUpdate ? 'แก้ไข' : 'สร้าง' }}ตั้งค่าบริษัทที่จะแยกบิล
    </div>
    <v-row class="pl-15 mb-5" v-if="form.is_split_bill">
      <v-col cols="3" class="text-center">
        <!-- <uploadImage
          directory="logo"
          :limit="2"
          :src="form.split_bill_data.logo_url"
          @onChangeUrl="form.split_bill_data.logo_url = $event"
        > -->
        <!-- <v-avatar color="primary" size="210" class="pointer"> -->
        <img
          :src="form.split_bill_data.logo_url || $defaultImage"
          width="210"
          height="auto"
        />
        <!--  </v-avatar> -->
        <!-- </uploadImage> -->

        <div class="mt-5 grey--text">
          Logo ร้านค้า หากต้องการเปลี่ยนรูป รบกวนแอดไลน์ id: @P2277 หรือ
          <a href="https://lin.ee/96rBlVP" target="_blank"
            >https://lin.ee/96rBlVP</a
          >
          <!-- <span
            class="layout-upload pointer primary--text"
            @click="deleteS3Obj(form.split_bill_data.logo_url)"
            disabled
          >
            ลบรูป
          </span> -->
        </div>
      </v-col>
    </v-row>
    <v-row v-if="form.is_split_bill">
      <v-col cols class="pl-15">
        <v-text-field
          name="split_bill_data_name"
          label="ชื่อร้านที่จะแสดงบนหัวใบเสร็จ"
          outlined
          dense
          v-model="form.split_bill_data.name"
          :rules="[() => !!form.split_bill_data.name || 'กรุณากรอกข้อมูล']"
          required
        />
        <v-text-field
          name="split_bill_data_phone_number"
          label="เบอร์ติดต่อ"
          outlined
          dense
          v-model="form.split_bill_data.phone_number"
          :rules="[
            () => !!form.split_bill_data.phone_number || 'กรุณากรอกข้อมูล',
          ]"
          required
        />
        <v-col>
          <v-row>
            <div class="mt-1">ใช้ที่อยู่เดียวกับด้านบน</div>
            <v-radio-group
              class="mt-0 ml-10"
              row
              v-model="form.split_bill_data.is_same_as_above"
              @change="
                (val) => {
                  if (val) form.split_bill_data.location = { ...form.location };
                }
              "
            >
              <v-radio name="" label="ใช่" :value="true"></v-radio>
              <!-- <v-spacer /> -->
              <v-radio name="" label="ไม่ใช่" :value="false"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <v-text-field
          name="split_bill_data_address"
          label="ที่อยู่"
          outlined
          dense
          :disabled="form.split_bill_data.is_same_as_above"
          @click="
            (dialogAddressData = form.split_bill_data.location),
              (dialogAddress = true),
              (dialogAddressKey = 'split_bill_data.location')
          "
          :value="`${form.split_bill_data.location.address} ${form.split_bill_data.location.sub_district} ${form.split_bill_data.location.district} ${form.split_bill_data.location.province} ${form.split_bill_data.location.postal_code}`"
        />
        <!-- v-model="form.split_bill_data.location" -->

        <v-text-field
          name="split_bill_data_line_id"
          label="Line ID (ควรใส่)"
          outlined
          dense
          v-model="form.split_bill_data.line_id"
        />
        <v-text-field
          name="split_bill_data_facebook"
          label="Facebook (ควรใส่)"
          outlined
          dense
          v-model="form.split_bill_data.facebook"
        />
      </v-col>
      <v-col cols offset="1">
        <v-select
          v-model="form.split_bill_category"
          :items="categories"
          item-value="_id"
          :item-text="(item) => `${item.name} ${item.broker}`"
          chips
          small-chips
          label="หมวดหมู่ย่อยสินค้าที่ออกบิลนี้"
          multiple
          dense
          outlined
          :rules="[
            () => form.split_bill_category.length >= 1 || 'กรุณากรอกข้อมูล',
          ]"
          required
        ></v-select>
        <v-col>
          <v-row>
            <div class="mt-1">เป็นนิติบุคคล ?</div>

            <v-radio-group class="mt-0 ml-10" row v-model="legalEntity2">
              <v-radio name="" label="เป็น" :value="true"></v-radio>
              <!-- <v-spacer /> -->
              <v-radio name="" label="ไม่เป็น" :value="false"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <div v-if="legalEntity2">
          <v-text-field
            name="split_bill_data_company_branch"
            label="สาขาบริษัท"
            outlined
            dense
            v-model="form.split_bill_data.company_branch"
          />
          <v-text-field
            name="split_bill_data_taxpayer_number"
            label="เลขประจำตัวผู้เสียภาษี"
            outlined
            dense
            v-model="form.split_bill_data.taxpayer_number"
            :error-messages="
              $v.form.split_bill_data.taxpayer_number.$error ? $error_text : ''
            "
          />
          <v-text-field
            name="split_bill_data_email"
            label="E - mail"
            outlined
            dense
            v-model="form.split_bill_data.email"
          />
        </div>
        <v-col>
          <v-row>
            <div class="mt-1">ภาษีมูลค่าเพิ่ม</div>
            <v-radio-group
              class="mt-0 ml-10"
              row
              v-model="form.split_bill_data.vat_registration"
            >
              <v-radio name="" label="จดภาษี" :value="true"></v-radio>
              <!-- <v-spacer /> -->
              <v-radio name="" label="ไม่จดภาษี" :value="false"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
        <v-col>
          <v-row>
            <div class="mt-1">
              ปริ้นใบเสร็จ หน้า-หลัง
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" small v-bind="attrs" v-on="on"
                    >mdi-information</v-icon
                  >
                </template>
                <span>เปิดใช้งานนี้ เพื่อพิมพ์ใบเสร็จแบบ 1 แผ่น หน้าหลัง</span>
              </v-tooltip>
            </div>
            <v-radio-group
              class="mt-0 ml-10"
              row
              v-model="form.setting.print_one_page"
            >
              <v-radio name="" label="หน้าเดียว" :value="false"></v-radio>
              <!-- <v-spacer /> -->
              <v-radio name="" label="หน้าหลัง" :value="true"></v-radio>
            </v-radio-group>
          </v-row>
        </v-col>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()">
          ปิด
        </v-btn>
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
        >
          บันทึกข้อมูล
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog persistent scrollable max-width="600" v-model="dialogUpdate">
      <BankAccount
        :item="form.bank_account"
        :delete_bank_account="form.delete_bank_account"
        @onClose="dialogUpdate = false"
        @onChange="onChangeBankAccount"
      />
    </v-dialog>

    <v-dialog
      persistent
      scrollable
      max-width="1200"
      v-model="dialogBusinessHoursUpdate"
    >
      <BusinessHours
        :item="form.open_hours"
        @onClose="dialogBusinessHoursUpdate = false"
        @onChange="(val) => (form.open_hours = val)"
      />
    </v-dialog>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>
  </div>
</template>

<script>
import BankAccount from '@/views/pages/setting/business-branch/BankAccount.vue';
import BusinessHours from '@/views/pages/setting/business-branch/BusinessHours.vue';
import {
  required,
  checked,
  email,
  maxLength,
  minLength,
  numeric,
  requiredIf,
} from 'vuelidate/lib/validators';
import AddressDetail from './AddressDetail.vue';
import uploadImage from '@/components/uploadImage.vue';
import S3 from 'aws-s3';

export default {
  components: {
    BankAccount,
    BusinessHours,
    AddressDetail,
    uploadImage,
  },
  data: () => ({
    // aws s3 file upload config
    file: null,
    uploading: false,
    aws_access_key_id: 'AKIA2ETG3H3HEVIJOFU3',
    aws_secret_access_key: '---',
    aws_default_region: 'ap-southeast-1',
    aws_bucket: 'permtang',
    dirName: 'logo',
    aws_url: 'https://permtang.s3-ap-southeast-1.amazonaws.com',

    legalEntity1: false,
    legalEntity2: false,
    form: {
      branch_id: 0,
      bank_account: [],
      branch_name: '',
      company_branch: '',
      // company_group_id: "",
      document_setting: {
        credit_note: { header: '', note: '' },
        debit_note: { header: '', note: '' },
        invoice: { header: '', note: '' },
        quotation: { header: '', note: '' },
        receipt: { header: '', note: '' },
        tax_invoice: { header: '', note: '' },
      },
      email: '',
      facebook: '',
      geopoint: { lat: '', lng: '' },
      is_split_bill: false,
      line_id: '',
      location: {
        address: '',
        district: '',
        postal_code: '',
        province: '',
        sub_district: '',
      },
      logo_url: '',
      qr_code_payment: '',
      name_for_receipt: '',
      open_hours: {
        friday: { open: false, time: [{ end: '', start: '' }] },
        monday: { open: false, time: [{ end: '', start: '' }] },
        saturday: { open: false, time: [{ end: '', start: '' }] },
        sunday: { open: false, time: [{ end: '', start: '' }] },
        thursday: { open: false, time: [{ end: '', start: '' }] },
        tuesday: { open: false, time: [{ end: '', start: '' }] },
        wednesday: { open: false, time: [{ end: '', start: '' }] },
      },
      phone_number: '',
      setting: {
        print_one_page: '',
        enable_product_merging: false,
        receipt_printing_format: 0,
        domain_for_qr: '',
        alert_onselect_cartype2: '',
        ban_car_province: [],
        car_tax_scrap: 0,
        cost_extension_car_tax: 0,
        cost_extension_motorbike_tax: 0,
        document_pickup_date: 0,
        document_pickup_time: '',
        merging_subcategory: [],
        ges_inspect_alert: false,
        letter_alert: false,
        reduce_bill_scrap: false,
        sms_alert: false,
        starting_province: '',
        tel_alert: false,
        third_insurance_tax_scrap: 0,
        CT1_3rdinsurance_express: '',
        CT2_3rdinsurance_express: '',
        CT3_3rdinsurance_express: '',
        CT4_3rdinsurance_express: '',
        other_3rdinsurance_express: '',
        qr_tracking: false,
        domain_for_qr: '',
        show_qr_code_payment: '',
      },
      split_bill_category: [],
      split_bill_data: {
        company_branch: '',
        email: '',
        facebook: '',
        line_id: '',
        location: {
          address: '',
          district: '',
          postal_code: '',
          province: '',
          sub_district: '',
        },
        logo_url: '',
        name: '',
        taxpayer_number: '',
        phone_number: '',
        vat_registration: false,
        is_same_as_above: false,
      },
      store_name: '',
      taxpayer_number: '',
      vat_registration: false,
    },

    dialogUpdate: false,
    dialogData: null,
    dialogBusinessHoursUpdate: false,
    dialogBusinessHoursData: null,
    dialogAddressKey: '',
    dialogAddress: false,
    dialogAddressData: {
      address: '',
      district: '',
      postal_code: '',
      province: '',
      sub_district: '',
    },

    selected: [],
    loading: false,
    categories: [],
    valid: true,
    rules: {
      required: (v) => !!v || 'this field is required',
    },
    list: {
      scrap: [
        {
          value: 0,
          name: 'ใบเสนอราคา, ใบแจ้งหนี้',
        },
        {
          value: 1,
          name: 'ใบเสนอราคา, ใบแจ้งหนี้, ใบเสร็จ',
        },
      ],
    }
  }),
  validations: function () {
    return {
      form: {
        // branch_name: { required },
        // store_name: { required },
        email: { email },
        name_for_receipt: { required },
        taxpayer_number: {
          numeric,
          //minLength: minLength(13),
          //maxLength: maxLength(13),
        },
        // split_bill_category: {
        //   required,
        // },
        split_bill_data: {
          // phone_number: {
          //   required: requiredIf(function () {
          //     return this.form.is_split_bill;
          //   }),
          // },
          // company_branch: {
          //   required: requiredIf(function() {
          //     return this.form.is_split_bill;
          //   })
          // },
          // email: {
          //   email: requiredIf(function() {
          //     return this.form.is_split_bill;
          //   })
          // },
          // location: {
          //   address: {
          //     required: requiredIf(function() {
          //       return this.form.is_split_bill;
          //     })
          //   },
          //   district: {
          //     required: requiredIf(function() {
          //       return this.form.is_split_bill;
          //     })
          //   },
          //   postal_code: {
          //     required: requiredIf(function() {
          //       return this.form.is_split_bill;
          //     })
          //   },
          //   province: {
          //     required: requiredIf(function() {
          //       return this.form.is_split_bill;
          //     })
          //   },
          //   sub_district: {
          //     required: requiredIf(function() {
          //       return this.form.is_split_bill;
          //     })
          //   }
          // },
          // name: {
          //   required: requiredIf(function () {
          //     return this.form.is_split_bill;
          //   }),
          // },
          taxpayer_number: {
            numeric,
            //minLength: minLength(13),
            //maxLength: maxLength(13),
          },
        },
      },
    };
  },
  created() {
    this.getCategory();
    if (this.isUpdate) this.getData();
    if (this.$route.query.dialogUpdate == true) {
      this.dialogUpdate = this.$route.query.dialogUpdate;
      this.$route.query.dialogUpdate = false;
    }
  },
  methods: {
    getCategory() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$store.state.selected_branch._id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      this.$axios
        .post(`${this.$baseUrl}/sub_category/get_sub_category`, body)
        .then(async (res) => {
          this.categories = res.result;
        });
    },
    getData() {
      let body = {
        token: this.$jwt.sign(
          { branch_id: this.$route.params.id },
          this.$privateKey,
          { noTimestamp: true }
        ),
      };
      this.$axios
        .post(`${this.$baseUrl}/branch/get_by_id`, body)
        .then(async (res) => {
          this.form = { ...res.result };
          if (this.form.taxpayer_number) {
            this.legalEntity1 = true;
          }
          if (this.form.split_bill_data.taxpayer_number) {
            this.legalEntity2 = true;
          }
          this.form.delete_bank_account = [];
        })
        .catch((err) => {
          console.log('err', err);
        })
        .then(() => {
          this.loading = false;
        });
    },
    async save() {
      // Trigger form validation
      this.$v.form.$touch();
      if (this.$v.form.$invalid) return;

      // Show confirmation prompt
      try {
        const result = await this.$alertConfirm({ title: 'ยืนยันบันทึกข้อมูล ?', text: '' });
        if (!result.isConfirmed) return;

        // Set branch_name from store_name
        this.form.branch_name = this.form.store_name;

        // Prepare form data
        const subPath = this.isUpdate ? 'edit_branch' : 'create_branch';
        if (this.isUpdate) {
          this.form = { branch_id: this.form._id, ...this.form };
          delete this.form._id;
        }

        // Create request body with signed JWT token
        const body = {
          token: this.$jwt.sign(this.form, this.$privateKey, { noTimestamp: true }),
        };

        // Set loading state
        this.loading = true;

        // Send request
        const response = await this.$axios.post(`${this.$baseUrl}/branch/${subPath}`, body);

        // Show success alert and redirect if needed
        this.$alertSuccess({ title: response.status });
        if (!this.isUpdate) {
          this.$router.push({ name: 'setting-business-branch' });
        }
      } catch (err) {
        console.error('Error saving data:', err);
        this.$alertServerError({ title: err.error_message || 'Server Error' });
      } finally {
        // Ensure loading state is reset and reload page
        this.loading = false;
        location.reload();
      }
    },
    close() {
      this.$router.push({ name: 'setting-business-branch' });
    },
    onChangeBankAccount(bankAccount, deleteList) {
      this.form.bank_account = bankAccount;
      if (this.isUpdate) this.form.delete_bank_account = deleteList;
    },
    onSubmitAddress(val) {
      if (this.dialogAddressKey == 'split_bill_data.location')
        this.form.split_bill_data.location = val;
      else {
        this.form[this.dialogAddressKey] = val;
        if (this.form.split_bill_data.is_same_as_above)
          this.form.split_bill_data.location = { ...val };
      }
      this.dialogAddress = false;
    },
    deleteS3Obj(file_path) {
      this.$alertConfirm({ title: `ต้องการลบรูป ?` }).then(async (result) => {
        if (result.isConfirmed) {
          const config = {
            bucketName: this.aws_bucket,
            dirName: this.dirName,
            region: this.aws_default_region,
            accessKeyId: this.aws_access_key_id,
            secretAccessKey: this.aws_secret_access_key,
            s3Url: this.aws_url,
          };
          let filename = file_path.split('/');
          filename = filename[filename.length - 1];
          const S3Client = new S3(config);
          S3Client.deleteFile(filename)
            .then((response) => {
              this.form.car_registration_url = '';
              const file = document.querySelector('.file');
              console.log(response);
            })
            .catch((err) => console.error(err));
        }
      });
    },
  },
  computed: {
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>